import { ActionTree, GetterTree, MutationTree } from "vuex";
import { StoreAddress } from "@/serviceClients/responses/KoalaStoreInformationResponse";

type State = {
  address: StoreAddress;
  isOpenAddressDialog: boolean;
  isOpenCreateBank: boolean;
  titleCreateBank: string;
};

const state: State = {
  address: {
    city: "",
    coordinateAddress: null,
    district: "",
    fullAddress: "",
    inputType: "",
    latitude: null,
    longitude: null,
    postalCode: "",
    province: "",
  },
  isOpenAddressDialog: false,
  isOpenCreateBank: false,
  titleCreateBank: "",
};
const getters: GetterTree<State, any> = {
  getAddress: (state) => state.address,
  getIsOpenAddressDialog: (state) => state.isOpenAddressDialog,
  getIsOpenCreateBank: (state) => state.isOpenCreateBank,
  getTitleCreateBank: (state) => state.titleCreateBank,
};

const mutations: MutationTree<State> = {
  updateAddress(state, value: StoreAddress) {
    state.address = value;
  },
  updateIsOpenAddressDialog(state, value: boolean) {
    state.isOpenAddressDialog = value;
  },
  updateIsOpenCreateBank(state, value: boolean) {
    state.isOpenCreateBank = value;
  },
  updateTitleCreateBank(state, value: string) {
    state.titleCreateBank = value;
  },
  updateCity(state, value: string) {
    state.address.city = value;
  },
  updateCoordinateAddress(state, value: string) {
    state.address.coordinateAddress = value;
  },
  updateDistrict(state, value: string) {
    state.address.district = value;
  },
  updateFullAddress(state, value: string) {
    state.address.fullAddress = value;
  },
  updateInputType(state, value: string) {
    state.address.inputType = value;
  },
  updateLatitude(state, value: number) {
    state.address.latitude = value;
  },
  updateLongitude(state, value: number) {
    state.address.longitude = value;
  },
  updatePostalCode(state, value: string) {
    state.address.postalCode = value;
  },
  updateProvince(state, value: string) {
    state.address.province = value;
  },
};

const actions: ActionTree<State, any> = {
  setAddress(context, value: StoreAddress) {
    context.commit("updateAddress", value);
  },
  clearAddress(context) {
    context.commit("updateAddress", {
      city: "",
      coordinateAddress: null,
      district: "",
      fullAddress: "",
      inputType: "",
      latitude: null,
      longitude: null,
      postalCode: "",
      province: "",
    });
  },
  setIsOpenAddressDialog(context, value: boolean) {
    context.commit("updateIsOpenAddressDialog", value);
  },
  setIsOpenCreateBank(context, value: boolean) {
    context.commit("updateIsOpenCreateBank", value);
  },
  setTitleCreateBank(context, value: string) {
    context.commit("updateTitleCreateBank", value);
  },
  setCity(context, value: string) {
    context.commit("updateCity", value);
  },
  setCoordinateAddress(context, value: string) {
    context.commit("updateCoordinateAddress", value);
  },
  setDistrict(context, value: string) {
    context.commit("updateDistrict", value);
  },
  setFullAddress(context, value: string) {
    context.commit("updateFullAddress", value);
  },
  setInputType(context, value: string) {
    context.commit("updateInputType", value);
  },
  setLatitude(context, value: number) {
    context.commit("updateLatitude", value);
  },
  setLongitude(context, value: number) {
    context.commit("updateLongitude", value);
  },
  setPostalCode(context, value: string) {
    context.commit("updatePostalCode", value);
  },
  setProvince(context, value: string) {
    context.commit("updateProvince", value);
  },
};

const StoreInfo = { state, getters, mutations, actions };

export default StoreInfo;
